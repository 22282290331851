<template>
  <div class="flex flex-col overflow-hidden relative pb-8 text-xs">
    <modal />
    <div class="w-full px-1 md:px-0 md:w-9/12 mx-auto min-h-screen">
      <notification />
      <brand-nav-bar />
      <navigation />
      <breadcrumbs
        :title="title"
        :action="actions"
        :upload="upload"
        :page-title="pageTitle"
        :permission="permission"
        :print="print"
      />

      <router-view @breadcrumbs="updateActionbar" />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Header/Navigation'
import BrandNavBar from '@/components/Header/BrandNavBar'

export default {
  name: 'main-layout',
  props: ['value'],
  components: { Navigation, BrandNavBar },
  data() {
    return {
      actions: null,
      title: null,
      upload: null,
      pageTitle: null,
      permission: {},
      print: false,
    }
  },
  mounted() {},
  methods: {
    updateActionbar(payload) {
      this.actions = payload?.actions
      this.title = payload?.title
      this.upload = payload.upload
      this.pageTitle = payload.pageTitle
      this.permission = payload.permission
      this.print = payload.print
    },
  },
}
</script>

<style>
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.75rem;
  min-width: 5rem;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}
.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}
.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='right'] {
  margin-left: 5px;
}
.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^='left'] {
  margin-right: 5px;
}
.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
