var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col md:flex-row md:justify-between md:items-center"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex items-center"},[_c('a',{staticClass:"text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700",attrs:{"href":"#"}},[(_vm.profile.org.logo)?_c('img',{staticClass:" h-12",attrs:{"src":_vm.profile.org.logo,"alt":_vm.profile.org.name}}):_c('span',[_vm._v("Kundenlogo")])])]),_c('div',{staticClass:"flex md:hidden"},[_c('button',{staticClass:"text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600",attrs:{"type":"button","aria-label":"toggle menu"},on:{"click":function($event){$event.preventDefault();_vm.menuItem = { 4: !_vm.menuItem['4'], current: 4 }}}},[_c('svg',{staticClass:"h-6 w-6 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"}})])])])]),_c('div',{directives:[{name:"away",rawName:"v-away",value:(_vm.close),expression:"close"}],staticClass:"md:flex items-center text-xs"},[_c('div',{staticClass:"flex items-center justify-between py-2 -mx-1 md:mx-0"},[_c('div',{staticClass:"relative"},[_c('a',{staticClass:"flex items-center h-10 leading-10 md:px-4 rounded cursor-pointer no-underline hover:no-underline transition-colors duration-100 mx-1 hover:bg-gray-100",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.menuItem = { 4: !_vm.menuItem['4'], current: 4 }}}},[_c('span',{staticClass:"mr-1 w-6 h-6"},[(_vm.profile && _vm.profile.avatar)?_c('img',{staticClass:"rounded-full w-6 h-6 object-cover",attrs:{"src":_vm.profile.avatar,"alt":_vm.profile.full_name}}):_c('avatar',{attrs:{"size":22,"username":_vm.profile.initial
                  ? _vm.profile.initial.split('').join(' ')
                  : _vm.profile.full_name}})],1),_c('span',[_vm._v(_vm._s(_vm.profile.full_name))]),_c('span',{staticClass:"ml-1 w-4 h-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuItem[4]),expression:"menuItem[4]"}],staticClass:"bg-white shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"},[_c('span',{staticClass:"absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"}),_c('div',{staticClass:"bg-white rounded w-full relative z-10 py-1"},[_c('ul',{staticClass:"list-reset"},[_c('li',{staticClass:"relative"},[_c('a',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.myProfile.apply(null, arguments)}}},[_c('span',{staticClass:"flex-1"},[_vm._v("Mein Personaldossier")])]),_c('a',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('span',{staticClass:"flex-1"},[_vm._v("Abmelden")])])])])])])]),_c('div',{staticClass:"relative"},[(
            _vm.can('Einstellungen', 'Einstellungen', 'Firmenprofil', 'edit', [
              'All' ]) ||
              _vm.can(
                'Einstellungen',
                'Einstellungen',
                'Allgemein Einstellung',
                'edit',
                ['All']
              )
          )?_c('a',{staticClass:"flex items-center h-10 leading-10 md:px-4 rounded cursor-pointer no-underline hover:no-underline transition-colors duration-100 mx-1 hover:bg-gray-100",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.menuItem = { 5: !_vm.menuItem['5'], current: 5 }}}},[_c('span',{staticClass:"mr-1 w-4 h-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z","clip-rule":"evenodd"}})])]),_c('span',[_vm._v("Einstellungen")]),_c('span',{staticClass:"ml-1 w-4 h-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuItem[5]),expression:"menuItem[5]"}],staticClass:"bg-white shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"},[_c('span',{staticClass:"absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"}),_c('div',{staticClass:"bg-white rounded w-full relative z-10 py-1"},[_c('ul',{staticClass:"list-reset"},[(
                  _vm.can(
                    'Einstellungen',
                    'Einstellungen',
                    'Firmenprofil',
                    'edit',
                    ['All', 'Only own personal data']
                  )
                )?_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/settings/update"}},[_c('span',{staticClass:"flex-1"},[_vm._v("Firmenprofil")])])],1):_vm._e(),(
                  _vm.can(
                    'Einstellungen',
                    'Einstellungen',
                    'Allgemein Einstellung',
                    'edit',
                    ['All', 'Only own personal data']
                  )
                )?_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/settings"}},[_c('span',{staticClass:"flex-1"},[_vm._v("Allgemeine Einstellungen")])])],1):_vm._e()])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }