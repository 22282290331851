<template>
  <div class="flex flex-col md:flex-row md:justify-between md:items-center">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <a
          class="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700"
          href="#"
        >
          <img
            v-if="profile.org.logo"
            :src="profile.org.logo"
            :alt="profile.org.name"
            class=" h-12"
          />
          <span v-else>Kundenlogo</span>
        </a>
      </div>

      <!-- Mobile menu button -->
      <div class="flex md:hidden">
        <button
          @click.prevent="menuItem = { 4: !menuItem['4'], current: 4 }"
          type="button"
          class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
          aria-label="toggle menu"
        >
          <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            ></path>
          </svg>
        </button>
      </div>
    </div>

    <div v-away="close" class="md:flex items-center text-xs">
      <div class="flex items-center justify-between py-2 -mx-1 md:mx-0">
        <div class="relative">
          <a
            href="#"
            class="flex items-center h-10 leading-10 md:px-4 rounded cursor-pointer no-underline hover:no-underline transition-colors duration-100 mx-1 hover:bg-gray-100"
            @click.prevent="menuItem = { 4: !menuItem['4'], current: 4 }"
          >
            <span class="mr-1 w-6 h-6">
              <img
                v-if="profile && profile.avatar"
                :src="profile.avatar"
                class="rounded-full w-6 h-6 object-cover"
                :alt="profile.full_name"
              />
              <avatar
                v-else
                :size="22"
                :username="
                  profile.initial
                    ? profile.initial.split('').join(' ')
                    : profile.full_name
                "
              />
            </span>
            <span>{{ profile.full_name }}</span>
            <span class="ml-1 w-4 h-4"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg
            ></span>
          </a>

          <div
            class="bg-white shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"
            v-show="menuItem[4]"
          >
            <span
              class="absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"
            ></span>
            <div class="bg-white rounded w-full relative z-10 py-1">
              <ul class="list-reset">
                <!-- <li class="relative">
                  <a
                    href="#"
                    class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                  >
                    <span class="flex-1">Profile</span>
                  </a>
                </li> -->
                <li class="relative">
                  <a
                    href="#"
                    @click.prevent="myProfile"
                    class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                  >
                    <span class="flex-1">Mein Personaldossier</span>
                  </a>
                  <a
                    href="#"
                    @click.prevent="logout"
                    class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                  >
                    <span class="flex-1">Abmelden</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="relative">
          <a
            href="#"
            class="flex items-center h-10 leading-10 md:px-4 rounded cursor-pointer no-underline hover:no-underline transition-colors duration-100 mx-1 hover:bg-gray-100"
            @click.prevent="menuItem = { 5: !menuItem['5'], current: 5 }"
            v-if="
              can('Einstellungen', 'Einstellungen', 'Firmenprofil', 'edit', [
                'All',
              ]) ||
                can(
                  'Einstellungen',
                  'Einstellungen',
                  'Allgemein Einstellung',
                  'edit',
                  ['All']
                )
            "
          >
            <span class="mr-1 w-4 h-4"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                /></svg
            ></span>
            <span>Einstellungen</span>
            <span class="ml-1 w-4 h-4"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg
            ></span>
          </a>

          <div
            class="bg-white shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"
            v-show="menuItem[5]"
          >
            <span
              class="absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"
            ></span>
            <div class="bg-white rounded w-full relative z-10 py-1">
              <ul class="list-reset">
                <li
                  class="relative"
                  v-if="
                    can(
                      'Einstellungen',
                      'Einstellungen',
                      'Firmenprofil',
                      'edit',
                      ['All', 'Only own personal data']
                    )
                  "
                >
                  <router-link
                    to="/settings/update"
                    class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                  >
                    <span class="flex-1">Firmenprofil</span>
                  </router-link>
                </li>
                <li
                  class="relative"
                  v-if="
                    can(
                      'Einstellungen',
                      'Einstellungen',
                      'Allgemein Einstellung',
                      'edit',
                      ['All', 'Only own personal data']
                    )
                  "
                >
                  <router-link
                    to="/settings"
                    class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                  >
                    <span class="flex-1">Allgemeine Einstellungen</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logoutUser } from '@/services/auth'

export default {
  name: 'brand-nav',
  props: [],
  data() {
    return {
      menuItem: {
        4: false,
        5: false,
        current: null,
      },
    }
  },
  methods: {
    myProfile(){
      this.$router.push(`/personal/${this.profile.personal.id}`)
    },
    logout() {
      logoutUser()
      this.$router.push('/login')
    },
    close() {
      this.menuItem = {}
    },
  },
  watch: {
    $route: function() {
      this.close()
    },
  },
}
</script>

<style>
.sub-menu {
}
</style>
