<template>
  <div
    v-away="close"
    class="text-white flex whitespace-no-wrap bg-blue-dark rounded"
  >
    <router-link class="hover:bg-blue-light rounded p-2" to="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
        />
      </svg>
    </router-link>
    <div class="relative">
      <a
        @click.prevent="menuItem = { 2: !menuItem['2'], current: 2 }"
        class="flex text-sm py-3 hover:bg-blue-light px-4 cursor-pointer"
        v-if="
          can('Personal', 'Personal', 'Personal', 'view', [
            'All',
            'Only own personal data',
            'Only own unit data',
          ]) ||
            can(
              'Personal',
              'Mitarbeitergespräche',
              'Mitarbeitergespräche',
              'view',
              ['All', 'Only own personal data', 'Only own unit data']
            )
        "
        >Personal
        <span class="ml-1 w-4 h-4 py-1"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            /></svg
        ></span>
      </a>
      <div
        class="bg-white text-blue-900 shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"
        v-show="menuItem[2]"
      >
        <span
          class="absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"
        ></span>
        <div class="bg-white rounded w-full relative z-10 py-1">
          <ul class="list-reset">
            <li
              class="relative"
              v-if="
                can('Personal', 'Personal', 'Personal', 'view', [
                  'All',
                  'Only own personal data',
                ]) ||
                  can('Personal', 'Personal', 'Personal', 'view', [
                    'All',
                    'Only own unit data',
                  ])
              "
            >
              <router-link
                class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                to="/personal"
                >Personal
              </router-link>
            </li>
            <li
              class="relative"
              v-if="
                can(
                  'Personal',
                  'Mitarbeitergespräche',
                  'Mitarbeitergespräche',
                  'view',
                  ['All', 'Only own personal data']
                ) ||
                  can(
                    'Personal',
                    'Mitarbeitergespräche',
                    'Mitarbeitergespräche',
                    'view',
                    ['All', 'Only own unit data']
                  )
              "
            >
              <router-link
                class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                to="/personal/my-performance-evaluation"
                >Mitarbeitergespräche
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="relative">
      <a
        @click.prevent="menuItem = { 3: !menuItem['3'], current: 3 }"
        v-if="
          can('Unternehmen', 'Organisation', 'Organisation', 'view', [
            'All',
            'Only own personal data',
            'Only own unit data',
          ])
        "
        class="flex text-sm py-3 hover:bg-blue-light px-4 cursor-pointer"
        >Unternehmen
        <span class="ml-1 w-4 h-4 py-1"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            /></svg
        ></span>
      </a>
      <div
        class="bg-white text-blue-900 shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"
        v-show="menuItem[3]"
      >
        <span
          class="absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"
        ></span>
        <div class="bg-white rounded w-full relative z-10 py-1">
          <ul class="list-reset">
            <li
              v-if="
                can('Unternehmen', 'Organisation', 'Organisation', 'edit', [
                  'All',
                ])
              "
              class="relative"
            >
              <router-link
                class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                to="/my-company"
                >Mein Unternehmen
              </router-link>
            </li>
            <li class="relative">
              <router-link
                class="px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer"
                to="/organisation"
                >Organisation
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  props: [],
  data() {
    return {
      menuItem: {
        2: false,
        3: false,
        current: null,
      },
    }
  },
  methods: {
    close() {
      this.menuItem = {}
    },
  },
  watch: {
    $route: function() {
      this.close()
    },
  },
}
</script>

<style></style>
