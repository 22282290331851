var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"away",rawName:"v-away",value:(_vm.close),expression:"close"}],staticClass:"text-white flex whitespace-no-wrap bg-blue-dark rounded"},[_c('router-link',{staticClass:"hover:bg-blue-light rounded p-2",attrs:{"to":"/"}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"}})])]),_c('div',{staticClass:"relative"},[(
        _vm.can('Personal', 'Personal', 'Personal', 'view', [
          'All',
          'Only own personal data',
          'Only own unit data' ]) ||
          _vm.can(
            'Personal',
            'Mitarbeitergespräche',
            'Mitarbeitergespräche',
            'view',
            ['All', 'Only own personal data', 'Only own unit data']
          )
      )?_c('a',{staticClass:"flex text-sm py-3 hover:bg-blue-light px-4 cursor-pointer",on:{"click":function($event){$event.preventDefault();_vm.menuItem = { 2: !_vm.menuItem['2'], current: 2 }}}},[_vm._v("Personal "),_c('span',{staticClass:"ml-1 w-4 h-4 py-1"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuItem[2]),expression:"menuItem[2]"}],staticClass:"bg-white text-blue-900 shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"},[_c('span',{staticClass:"absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"}),_c('div',{staticClass:"bg-white rounded w-full relative z-10 py-1"},[_c('ul',{staticClass:"list-reset"},[(
              _vm.can('Personal', 'Personal', 'Personal', 'view', [
                'All',
                'Only own personal data' ]) ||
                _vm.can('Personal', 'Personal', 'Personal', 'view', [
                  'All',
                  'Only own unit data' ])
            )?_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/personal"}},[_vm._v("Personal ")])],1):_vm._e(),(
              _vm.can(
                'Personal',
                'Mitarbeitergespräche',
                'Mitarbeitergespräche',
                'view',
                ['All', 'Only own personal data']
              ) ||
                _vm.can(
                  'Personal',
                  'Mitarbeitergespräche',
                  'Mitarbeitergespräche',
                  'view',
                  ['All', 'Only own unit data']
                )
            )?_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/personal/my-performance-evaluation"}},[_vm._v("Mitarbeitergespräche ")])],1):_vm._e()])])])]),_c('div',{staticClass:"relative"},[(
        _vm.can('Unternehmen', 'Organisation', 'Organisation', 'view', [
          'All',
          'Only own personal data',
          'Only own unit data' ])
      )?_c('a',{staticClass:"flex text-sm py-3 hover:bg-blue-light px-4 cursor-pointer",on:{"click":function($event){$event.preventDefault();_vm.menuItem = { 3: !_vm.menuItem['3'], current: 3 }}}},[_vm._v("Unternehmen "),_c('span',{staticClass:"ml-1 w-4 h-4 py-1"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuItem[3]),expression:"menuItem[3]"}],staticClass:"bg-white text-blue-900 shadow-md rounded border border-gray-300 text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-1"},[_c('span',{staticClass:"absolute top-0 left-0 w-3 h-3 bg-white border transform rotate-45 -mt-1 ml-6"}),_c('div',{staticClass:"bg-white rounded w-full relative z-10 py-1"},[_c('ul',{staticClass:"list-reset"},[(
              _vm.can('Unternehmen', 'Organisation', 'Organisation', 'edit', [
                'All' ])
            )?_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/my-company"}},[_vm._v("Mein Unternehmen ")])],1):_vm._e(),_c('li',{staticClass:"relative"},[_c('router-link',{staticClass:"px-4 py-2 flex w-full items-start hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 cursor-pointer",attrs:{"to":"/organisation"}},[_vm._v("Organisation ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }